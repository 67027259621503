import { mapState } from 'vuex'
import { cloneDeep, isMatchWith } from 'lodash'
import config from '@/config'
import moment from 'moment'

const { THEME_NAME, GAMIFICATION_ENABLE, HIDDEN_TOOLS } = config

const DIGIT = '9'
const ALPHA = 'A'
const ALPHANUM = 'S'

export const mixin = {
  computed: {
    ...mapState([
      'Account',
      'Auth'
    ]),
    $theme () {
      return THEME_NAME
    },
    $hiddenTools () {
      return HIDDEN_TOOLS
    },
    $media () {
      return {
        mobile: this.$mq === 'mobile',
        tablet: this.$mq === 'tablet',
        desktop: this.$mq === 'desktop'
      }
    }
  },
  methods: {
    getContextPermission (context) {
      if (!context) {
        return null
      }

      if (!this.Auth.isUserRoot) {
        const filteredPermission = this.Auth.userData.permissions.filter((permission) => {
          return permission.context === context
        })

        return filteredPermission[0] ? 'write' : null
      } else {
        return 'write'
      }
    },
    debounceEvent (callback, time = 300) {
      clearTimeout(this.interval)

      this.interval = setTimeout(() => {
        this.interval = null
        callback(arguments)
      }, time)
    },
    countPages (amount, perPage) {
      if (amount % perPage !== 0) {
        return Math.floor(amount / perPage) + 1
      }

      return amount / perPage
    },
    formatDate (date) {
      if (!date) return '-'
      date = date.slice(0, 10)

      return date && date.length > 0 && moment(date).format('DD/MM/YYYY')
    },
    formatDateTime (date) {
      if (!date) return '-'

      return date && date.length > 0 && moment(date).format('DD/MM/YYYY HH:mm')
    },
    formatTime: (date) => {
      if (!date) return '-'

      return date && date.length > 0 && moment(date).format('HH:mm')
    },
    formatDateFromJS (date) {
      if (!date) return '-'

      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    formatMask: (value, type, mask = false) => {
      let pattern = mask

      if (!mask) {
        switch (type) {
          case 'CEP':
            pattern = '99999-999'
            break
          case 'PHONE':
            pattern = '(99) 99999-9999'
            break
          case 'CPF':
            pattern = '999.999.999-99'
            break
          case 'CNPJ':
            pattern = '99.999.999/9999-99'
            break
          case 'money':
            pattern = '999.999,99'
            break
        }
      }

      // All the credits of this function goes to https://github.com/vanilla-masker/vanilla-masker
      // I was searching for something to use mask on an Label. I found this Lib but I couldn't make it run no Vue properly
      // So I changed his code to work on my context, Thanks Vanilla Masker - Fernando Fleury
      if (!pattern && !mask) return value
      const patternChars = pattern.replace(/\W/g, '')
      const output = pattern.split('')
      const values = value.toString()
        .replace(/\W/g, '') + (type === 'money' ? '00' : '')
      const charsValues = values.replace(/\W/g, '')
      let index = 0
      let i
      const outputLength = output.length

      for (i = 0; i < outputLength; i++) {
        if (index >= values.length) {
          if (patternChars.length === charsValues.length) {
            return output.join('')
          } else {
            break
          }
        } else {
          if ((output[i] === DIGIT && values[index].match(/[0-9]/)) ||
            (output[i] === ALPHA && values[index].match(/[a-zA-Z]/)) ||
            (output[i] === ALPHANUM && values[index].match(/[0-9a-zA-Z]/))) {
            output[i] = values[index++]
          } else if (output[i] === DIGIT || output[i] === ALPHA || output[i] === ALPHANUM) {
            return output.slice(0, i)
              .join('')
          }
        }
      }

      return output.join('')
        .substr(0, i)
    },
    formatInstallments (installmentObj) {
      if (!installmentObj) return null

      if (installmentObj.installment > 1) {
        let formattedInstallmentString = ''

        formattedInstallmentString += `${installmentObj.installment}x de `
        formattedInstallmentString += this.formatInterestCurrency(installmentObj.installmentValue)

        if (installmentObj.interestMonthValue > 0) {
          formattedInstallmentString += ' com juros de '
          formattedInstallmentString += this.formatInterestCurrency(installmentObj.interestMonthValue)
          formattedInstallmentString += '% a.m.'
        } else {
          formattedInstallmentString += ' sem juros'
        }

        return formattedInstallmentString
      } else {
        return `1x de ${this.formatCurrency(installmentObj.installmentValue || 0.0)}`
      }
    },
    formatTransactionId (transactionId) {
      const zeroPad = (num, places) => String(num).padStart(places, '0')

      return `#${zeroPad(transactionId, 6)}`
    },
    canRead (context) {
      if (this.Account.user == null) {
        return false
      }

      if (this.Account.user.currentProfile !== 'agent') {
        return true
      }

      const denied = this.Account.user.permissionsDenied.filter((item) => {
        return item.context === context && item.actionType === 'read'
      })

      return denied.length === 0
    },
    deepClone (source) {
      return cloneDeep(source)
    },
    isDeeplyEqual (obj, src) {
      return isMatchWith(obj, src, (objValue, srcValue) => {
        if (typeof objValue === 'object' && typeof srcValue === 'object') {
          if (Array.isArray(objValue) && objValue.length !== srcValue.length) {
            return false
          }

          return this.isDeeplyEqual(objValue, srcValue)
        }

        if (objValue.toString() !== srcValue.toString()) {
          return false
        }

        return true
      })
    },
    slugify (str) {
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()

      // remove accents, swap ñ for n, etc
      const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;'
      const to = 'aaaaaeeeeiiiioooouuuunc------'

      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

      return str
    },
    equalsProfile (profile) {
      return this.Account.user && this.Account.user.currentProfile === profile
    },
    notEqualsProfile (profile) {
      return this.Account.user && this.Account.user.currentProfile !== profile
    },
    isStudent () {
      return this.equalsProfile('student')
    },
    isGamificationEnable () {
      return GAMIFICATION_ENABLE
    },
    hydrateSolution (vueSolution, apiSolution) {
      vueSolution = {
        ...vueSolution,
        ...apiSolution
      }

      if (!apiSolution) {
        return vueSolution
      }

      if (apiSolution.certificate) {
        vueSolution.certificateEnabled = true
        vueSolution.certificateId = apiSolution.certificate.id
      } else {
        vueSolution.certificateEnabled = false
      }

      if (apiSolution.solutionSurvey.length > 0) {
        apiSolution.solutionSurvey.forEach(element => {
          switch (element.survey.type) {
            case 'satisfaction':
              vueSolution.surveyEnabled = true
              vueSolution.surveyId = element.survey.id
              break
            case 'subscription':
              vueSolution.subscriptionSurveyEnabled = true
              vueSolution.subscriptionSurveyId = element.survey.id
              vueSolution.surveyRequired = element.surveyRequired
              break
          }
        })
      } else {
        vueSolution.surveyEnabled = false
        vueSolution.subscriptionSurveyEnabled = false
      }

      vueSolution.showOnPortalEnabled = apiSolution.showOnPortal

      vueSolution.classType = apiSolution.sessionType
      vueSolution.conclusionDeadline = apiSolution.sessionDueTime
      vueSolution.reenrollmentDeadline = apiSolution.sessionReEnrollmentAwaitTime
      vueSolution.integrationCourseId = apiSolution.solutionIntegration[0].vendorId
      vueSolution.portfolioId = apiSolution.solutionIntegration[0].data.portfolioId
      vueSolution.sasId = apiSolution.solutionIntegration[0].data.sasId
      vueSolution.sasUnityId = apiSolution.solutionIntegration[0].data.sasUnityId
      vueSolution.sasProjectId = apiSolution.solutionIntegration[0].data.sasProjectId
      vueSolution.sasActionId = apiSolution.solutionIntegration[0].data.sasActionId

      if (apiSolution.solutionType.alias === 'dicas_whatsapp') {
        vueSolution.conclusionGroup = apiSolution.solutionIntegration[0].data.vendorFinishId
        vueSolution.triggerWords = apiSolution.solutionIntegration[0].data.vendorKey
      }

      vueSolution.rae_sync_session = apiSolution.raeSyncSession
      vueSolution.rae_sync_enrollment = apiSolution.raeSyncEnrollment
      vueSolution.rae_exclusive_enrollment = apiSolution.raeExclusiveEnrollment

      vueSolution.workload = {
        type: apiSolution.workload ? apiSolution.workload.match(/[hdw|min]+/gi) && apiSolution.workload.match(/[hdw|min]+/gi)[0] : 'h',
        number: apiSolution.workload ? apiSolution.workload.match(/[0-9]+/gi) && apiSolution.workload.match(/[0-9]+/gi)[0] : 0
      }

      vueSolution.solutionThemes = []

      if (apiSolution.solutionTheme) {
        apiSolution.solutionTheme.forEach(solutionTheme => {
          vueSolution.solutionThemes.push(solutionTheme.theme.alias)
        })
      }

      vueSolution.targetAudiences = []

      if (apiSolution.solutionTargetAudience) {
        apiSolution.solutionTargetAudience.forEach(solutionTargetAudience => {
          vueSolution.targetAudiences.push(solutionTargetAudience.targetAudience.id)
        })
      }

      vueSolution.seo = {
        title: apiSolution.seoTitle,
        description: apiSolution.seoDescription,
        keywords: apiSolution.seoKeywords
      }

      vueSolution.linkedPaths = []

      if (apiSolution.learningPathSolution) {
        vueSolution.showPaths = apiSolution.learningPathSolution.length > 0

        apiSolution.learningPathSolution.forEach(learningPathSolution => {
          vueSolution.linkedPaths.push({
            id: learningPathSolution.learningPath.id,
            name: learningPathSolution.learningPath.name
          })
        })
      }

      vueSolution.exclusiveToThematicPagesEnabled = apiSolution.exclusiveToThematicPages
      vueSolution.exclusiveToCompanyEnabled = apiSolution.exclusiveToCompany
      vueSolution.linkedThematicPages = []

      if (apiSolution.thematicPageSolution) {
        vueSolution.showThematicPages = apiSolution.thematicPageSolution.length > 0

        apiSolution.thematicPageSolution.forEach(thematicPageSolution => {
          vueSolution.linkedThematicPages.push({
            id: thematicPageSolution.thematicPage.id,
            name: thematicPageSolution.thematicPage.name
          })
        })
      }

      if (apiSolution.file) {
        vueSolution.fileId = apiSolution.file.id
        vueSolution.file = null
      }

      vueSolution.recommendedSolutions = []

      if (apiSolution.solutionRecommendations) {
        apiSolution.solutionRecommendations.forEach(recommendation => {
          vueSolution.recommendedSolutions.push({
            id: recommendation.recommendationDTO.id,
            name: recommendation.recommendationDTO.name
          })
        })
      }

      return vueSolution
    },

    formatCurrency (amount, type) {
      const formattedCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
        .format(amount)

      if (!type) {
        return formattedCurrency
      }

      const signal = type === 'income' ? '' : '-'

      return `${signal} ${formattedCurrency}`
    },

    formatInterestCurrency (amount, type) {
      amount = Math.floor((amount * 100)) / 100
      const formattedCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
        .format(amount)

      if (!type) {
        return formattedCurrency
      }

      const signal = type === 'income' ? '' : '-'

      return `${signal} ${formattedCurrency}`
    }
  }
}
