/* Legado */
import Auth from '@/domains/Auth/vuex'
import Users from '@/domains/Users/vuex'
import Sessions from '@/domains/Sessions/vuex'
import Solutions from '@/domains/Solutions/vuex'
import Dashboard from '@/domains/Dashboard/vuex'
import ThematicPages from '@/domains/ThematicPages/vuex'
import Groups from '@/domains/Groups/vuex'
import Management from '@/domains/Management/vuex'
import Comments from '@/domains/Comments/vuex'
import Modules from '@/domains/Modules/vuex'
import Notifications from '@/domains/Notifications/vuex'
import Reports from '@/domains/Reports/vuex'
import Tracks from '@/domains/Tracks/vuex'
import Programs from '@/domains/Programs/vuex'
import Banners from '@/domains/Banners/vuex'
import Gamification from '@/domains/Gamification/vuex'
import Events from '@/domains/Events/vuex'
import ClientConfig from '@/domains/ClientConfig/vuex'

/* Novo modelo para criar storage. Utilizar sufixo "Module" para evitar conflitos */
import { EmailModule } from '@/modules/Notification/modules/Email'
import { FinanceModule } from '@/modules/Finance/modules/Finance'

const vuex = {
  Auth,
  Solutions,
  Users,
  Dashboard,
  Sessions,
  Tracks,
  Programs,
  Reports,
  ThematicPages,
  Groups,
  Modules,
  Management,
  Events,
  ClientConfig,
  Comments,
  Notifications,
  Banners,
  Gamification,
  EmailModule,
  FinanceModule
}
const keys = Object.keys(vuex)
const modules = keys.reduce((acc, key) => ({
  ...acc,
  [key]: vuex[key].module
}), {})

export default { modules }
