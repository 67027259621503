import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetClientConfig = async ({ commit }) => {
  return services.getClientConfig()
    .then(clientConfig => {
      commit(TYPES.SET_CLIENT_CONFIG, clientConfig.data)

      return clientConfig
    })
}
