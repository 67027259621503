<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import modulesMixin from '@/modules/Modules/modulesMixin'

export default {
  name: 'Main',
  components: {
    Toast: () => import('@/components/general/Toast'),
    Spinner: () => import('@/components/general/Spinner'),
    VerticalMenu: () => import('@/components/general/VerticalMenu')
  },

  mixins: [ modulesMixin ],

  data () {
    return {
      menuExpanded: true,
      menuOpen: true,
      menuItems: []
    }
  },

  computed: {
    ...mapState([
      'Account',
      'Auth',
      'accessibility',
      'fullscreen',
      'accessibilityFontSize'
    ]),

    ...mapGetters([
      'activeModules',
      'isUserRoot',
      'getUserData'
    ]),

    shouldShowNavigation () {
      return !this.$route.meta.hideNavbar || false
    },

    permissions () {
      return this.Auth.userData.permissions
    }
  },

  watch: {
    '$route.name' () {
      if (!this.$route.meta.permission) {
        return
      }

      const permission = this.getContextPermission(this.$route.meta.permission.context)

      if (permission === 'write' && [
        'read',
        'write'
      ].indexOf(this.$route.meta.permission.access) !== -1) {
        if (!this.$route.meta.module || this.activeModules.includes(this.$route.meta.module)) {
          return
        }
      }

      this.$router.push({ name: 'not.allowed' })
    },

    'activeModules' () {
      this.createMenuTabManagement()
      this.createMenuVertical()
    },

    'permissions' () {
      this.createMenuTabManagement()
      this.createMenuVertical()
    }
  },

  mounted () {
    this.$theme !== 'default' && document.body.classList.add(`theme-${this.$theme}`)
  },

  created () {
    this.attemptGetActiveModulesList({
      query: {},

      filter: {},
      limit: 20
    })

    if (!document.querySelector('.symbol-defs')) {
      const svg = require('@/../public/assets/images/themes/default/svg/symbol-defs.svg')
      const tmp = document.createElement('div')

      tmp.innerHTML = svg
      document.body.appendChild(tmp.childNodes[0])
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptLogout',
      'attemptGetActiveModulesList'
    ]),

    resizeMenu (value) {
      this.menuExpanded = value
    },

    closeMenu () {
      this.menuOpen = false
    }
  }
}
</script>

<template>
  <div id="app">
    <Spinner />
    <VerticalMenu
      v-if="shouldShowNavigation"
      :items="VERTICAL_MENU_ITEMS"
      @resize="resizeMenu"
      @close="closeMenu"
    />
    <main class="main">
      <RouterView :key="$route.fullPath" />
    </main>
    <Toast />
  </div>
</template>

<style src="@/assets/styles/themes/default/global.css"></style>
