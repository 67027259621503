import { isBefore, subMinutes, parse as parseDate } from 'date-fns'
import jwtDecode from 'jwt-decode'
import * as TYPES from '@/store/types'
import * as axios from 'axios'
import { app } from '@/main'

const tokenIsValid = (token) => {
  const { exp } = jwtDecode(token)

  return isBefore(new Date(), subMinutes(parseDate(exp, 't', new Date()), 10))
}

const exceptions = [ 'api/login_check' ]

export default (http, store, router) => {
  http.interceptors.response.use(
    response => {
      const token = response.data.token && jwtDecode(response.data.token)

      if (token && !token.roles.includes('ROLE_ADMIN') && !response.request.responseURL.includes('api/impersonate')) {
        throw new Error('Invalid_Profile')
      }

      return response
    },
    error => {
      const { response } = error

      if (
        !!response.data.error &&
        response.data.error.code === 403 &&
        response.data.error.message === 'access_not_allowed'
      ) {
        store.dispatch('setFeedback', { message: app.$t('global.error.permission') })
        router.push({ name: 'not.allowed' })
        throw app.$nextTick(() => {
          throw response
        })
      }

      if (response.status === 401) {
        if (!response.request.responseURL.includes('api/login_check')) {
          router.push({ name: 'signin' })
        }
      }

      if (response.status === 503 && !response.request.responseURL.includes('api/login_check')) {
        router.push({ name: 'unavailable' })
      }

      throw response
    }
  )

  http.interceptors.request.use(config => {
    if (store.state.language) {
      store.commit(TYPES.MAIN_SET_LANGUAGE, store.state.language)
    }

    let isExternalApi = false

    try {
      const url = (new URL(config.url))

      isExternalApi = url.origin !== config.baseURL
    } catch (err) {
    }

    return new Promise((resolve, reject) => {
      for (const route of exceptions) {
        if (config.url === route || isExternalApi) {
          resolve(config)

          return
        }
      }

      config.isLogout = false

      store.dispatch('attemptCheckUserToken')
        .then((data) => {
          if (data.token != null && tokenIsValid(data.token)) {
            config.headers.Authorization = `Bearer ${data.token}`
            resolve(config)
          } else {
            const newAxiosInstance = axios.create({
              baseURL: config.baseURL
            })

            newAxiosInstance.post('/api/token/refresh', { refresh_token: data.refreshToken })
              .then(response => {
                const newToken = response.data.token
                const newRefreshToken = response.data.refresh_token

                store.dispatch('updateToken', {
                  token: newToken,
                  refreshToken: newRefreshToken
                })

                config.headers.Authorization = `Bearer ${newToken}`
                resolve(config)
              })
              .catch(error => {
                store.dispatch('attemptLogout')
                config.headers.Authorization = null
                config.isLogout = true
                reject(error)
              })
          }
        })
    })
  })
}
