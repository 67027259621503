import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'Auth' ]),
    ...mapGetters([ 'activeModules' ]),
    isGamificationEnabled () {
      return this.activeModules.includes('gamification')
    },
    isWebhookEnabled () {
      return this.activeModules.includes('webhook')
    },

    isActiveTargetAudienceModule () {
      return this.activeModules.includes('target-audience')
    },

    permissionsManagement () {
      return this.Auth.userData.permissions
    },
    availableTabLink () {
      return this.tabLinks.filter(tab => !tab.disabled)
    }
  },
  created () {
    this.createMenuTabManagement()
  },
  watch: {
    isWebhookEnabled: {
      handler (value) {
        this.tabLinks = this.tabLinks.map((tab) => {
          if (tab.location.name === 'management.webhooks') {
            tab.disabled = !value
          }

          return tab
        })
      }
    },

    permissionsManagement: {
      handler () {
        this.tabLinks = this.tabLinks.map((tab) => {
          if (!this.getContextPermission(tab.permission) === 'write') {
            tab.disabled = true
          }

          return tab
        })
      }
    }
  },
  methods: {
    createMenuTabManagement () {
      this.tabLinks = [
        {
          location: { name: 'management.index' },
          text: this.$t('management.tabs:link.1'),
          permission: 'target_audience',
          disabled: (!this.getContextPermission('target_audience') && this.isActiveTargetAudienceModule) && !this.getContextPermission('solution_themes')
        },
        {
          location: { name: 'management.user.profiles' },
          text: this.$t('management.tabs:link.2'),
          permission: 'profiles',
          disabled: !this.getContextPermission('profiles')
        },
        {
          location: { name: 'management.survey' },
          text: this.$t('management.tabs:link.4'),
          permission: 'surveys',
          disabled: !this.getContextPermission('surveys')
        },
        {
          location: { name: 'management.certificates' },
          text: this.$t('management.tabs:link.5'),
          permission: 'certificates',
          disabled: !this.getContextPermission('certificates')
        },
        {
          location: { name: 'management.banners' },
          text: this.$t('management.tabs:link.6'),
          disabled: this.clientConfig?.clientAlias === 'schuster' || !this.getContextPermission('banners'),
          permission: 'banners'
        },
        {
          location: { name: 'management.gamification' },
          text: this.$t('management.tabs:link.7'),
          disabled: !this.isGamificationEnabled || !this.getContextPermission('gamification'),
          permission: 'gamification'
        },
        {
          location: { name: 'management.settings' },
          text: this.$t('management.tabs:link.settings'),
          disabled: !this.getContextPermission('configurations'),
          permission: 'configurations'
        },
        {
          location: { name: 'management.webhooks' },
          text: 'Webhooks',
          disabled: !this.isWebhookEnabled || !this.getContextPermission('webhooks'),
          permission: 'webhooks'
        }
      ]
    }
  },
  data () {
    return {
      tabLinks: null
    }
  }
}
