import { mapActions, mapGetters } from 'vuex'
import managementMixin from '@/modules/Management/managementMixin'

export default {
  mixins: [ managementMixin ],
  data () {
    return {
      VERTICAL_MENU_ITEMS: null
    }
  },
  computed: {
    ...mapGetters([
      'activeModules',
      'isUserRoot'
    ])
  },
  created () {
    this.attemptGetClientConfig()
    this.createMenuTabManagement()
    this.createMenuVertical()
  },
  methods: {
    ...mapActions([
      'attemptLogout',
      'attemptGetClientConfig'
    ]),
    createMenuVertical () {
      if (this.isUserRoot) {
        this.VERTICAL_MENU_ITEMS = [
          {
            items: [
              {
                text: 'global:menu.logout',
                icon: 'logout',
                bottom: true,
                exact: true,
                roles: [
                  'ROLE_ADMIN',
                  'ROLE_ROOT'
                ],
                callback: () => {
                  this.attemptLogout()
                }
              },
              {
                text: 'global:menu.modules',
                icon: 'dashboard',
                roles: [ 'ROLE_ROOT' ],
                alias: 'modules',
                link: {
                  name: 'modules.index'
                }
              }
            ]
          }
        ]
      } else {
        this.VERTICAL_MENU_ITEMS = [
          {
            items: [
              {
                text: 'global:menu.dashboard',
                permission: 'dashboard',
                icon: 'dashboard',
                alias: 'dashboard',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'dashboard.index'
                },
                disabled: !this.getContextPermission('dashboard') || !this.activeModules.includes('dashboard')
              },
              {
                text: 'global:menu.solutions',
                permission: 'solution',
                icon: 'account_balance',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'solutions.index'
                },
                disabled: !this.getContextPermission('solution')
              },
              {
                text: 'global:menu.tracks',
                permission: 'tracks',
                icon: 'timeline',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'tracks.index'
                },
                disabled: !this.getContextPermission('tracks')
              },
              {
                text: 'global:menu.programs',
                permission: 'programs',
                icon: 'programs',
                alias: 'program',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'programs.index'
                },
                disabled: !this.getContextPermission('programs') || !this.activeModules.includes('program')
              },
              {
                text: 'global:menu.pages',
                permission: 'thematic_pages',
                icon: 'perm_media',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'thematicPages.index'
                },
                disabled: !this.getContextPermission('thematic_pages') || !this.activeModules.includes('thematic-page')
              },
              {
                text: 'global:menu.sessions',
                permission: 'sessions',
                icon: 'collections_bookmark',
                alias: 'closed-session',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'sessions.index'
                },
                disabled: !this.getContextPermission('sessions') || !this.activeModules.includes('closed-session')
              },
              {
                text: 'global:menu.finances',
                permission: 'finances',
                icon: 'finances',
                alias: 'ecommerce',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'finance.list'
                },
                disabled: !this.getContextPermission('finances') || !this.activeModules.includes('ecommerce')
              },
              {
                text: 'global:menu.notifications',
                permission: 'notification',
                icon: 'email',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'notifications.emails'
                },
                disabled: !this.getContextPermission('notification')
              },
              {
                text: 'global:menu.events',
                permission: 'events',
                alias: 'event',
                icon: 'calendar-range',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'events.index'
                },
                disabled: !this.getContextPermission('events') || !this.activeModules.includes('event')
              },
              {
                text: 'global:menu.users',
                permission: 'users',
                icon: 'person',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'users.index'
                },
                disabled: !this.getContextPermission('users')
              },
              {
                text: 'global:menu.groups',
                permission: 'groups',
                icon: 'people',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'groups.index'
                },
                disabled: !this.getContextPermission('groups')
              },
              {
                text: 'global:menu.reports',
                permission: 'reports',
                icon: 'poll',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'reports.index'
                },
                disabled: ![
                  'reports_students',
                  'reports_enrollments',
                  'reports_surveys_answers',
                  'reports_learning_path_resume',
                  'reports_solutions_resume',
                  'reports_rae_services',
                  'reports_solution_alerts',
                  'reports_sas_events',
                  'reports_financial',
                  'reports_comments',
                  'reports_enrollments_program',
                  'reports_enrollments_learning_path',
                  'reports_programs_resume'
                ].some(permission => !!this.getContextPermission(permission))
              },
              {
                text: 'global:menu.management',
                icon: 'settings',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: this.availableTabLink && this.availableTabLink.length ? this.availableTabLink[0].location.name : ''
                },
                disabled: !(this.availableTabLink && this.availableTabLink.length)
              },
              {
                text: 'global:menu.comments',
                permission: 'comments',
                icon: 'comments',
                alias: 'assessment',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'comments.index'
                },
                disabled: !this.getContextPermission('comments') || !this.activeModules.includes('assessment')
              },
              {
                text: 'global:menu.tickets',
                icon: 'view-list',
                alias: 'support',
                roles: [ 'ROLE_ADMIN' ],
                link: {
                  name: 'tickets.underconstruction'
                },
                disabled: !this.activeModules.includes('support')
              },
              {
                text: 'global:menu.logout',
                icon: 'logout',
                bottom: true,
                exact: true,
                roles: [
                  'ROLE_ADMIN',
                  'ROLE_ROOT'
                ],
                callback: () => {
                  this.attemptLogout()
                }
              }
            ]
          }
        ]
      }
    }
  }
}
